<template>
    <div class="full-height-layout fill mr-10 ml-10">
        <div v-if="isAutoGenerating">
            <h3 class="pl-10" v-if="supplierProductImportedDetails.packageConfiguration.autoGenerated">Package configuration is being auto generated.</h3>
        </div>
        <div class="pl-10" v-if="!initialValues.packageConfiguration">
            <p>Choose way to create package configuration, either do it manually or use recommended selection</p>
            <mercur-radio v-model="type" value="manual">Select manually</mercur-radio>
            <mercur-radio v-model="type" data-test="supplierImportProductArtworkAutomatically" value="auto">Generate automatically</mercur-radio>
        </div>
        <product-packages-view
            v-if="supplierProductImportedDetails && type === 'manual'"
            :parent-product-configuration="productConfiguration"
            @change="setConfiguration"
            class="fill fhf pr-20"
        />
        <h3 class="pl-10" v-if="type === 'auto'" data-test="supplierImportProductArtworkConfirmationMessage">Package configuration will be generated automatically!</h3>

        <div class="text-right">
            <mercur-button
                v-if="initialValues.packageConfiguration"
                class="btn btn-yellow"
                :disabled="isSavingProduct"
                @click="() => save({regenerate: true})"
            >Save and regenerate</mercur-button>
            <mercur-button
                class="btn btn-yellow"
                type="submit"
                :disabled="isSavingProduct || isAutoGenerating"
                @click="checkAndSave"
                data-test="supplierImportProductProceed"
            >Save and continue</mercur-button>
        </div>

        <mercur-dialog :is-open.sync="confirmationDialog.isOpen">
            <div slot="header">
                <h3>Save and continue?</h3>
            </div>
            <div slot="default">
                <p class="max-width--700">
                    Package configuration is recommended to be regenerated before publishing the product.
                    Are you sure you wish to save and continue without regenerating?
                </p>
            </div>
            <div slot="footer">
                <mercur-button class="btn" @click="confirmationDialog.isOpen = false">Cancel</mercur-button>
                <mercur-button class="btn btn-yellow" @click="save">Save and continue</mercur-button>
            </div>
        </mercur-dialog>
    </div>
</template>

<script>
import SupplierImportedProductMixin from './SupplierImportedProductMixin'
import ProductPackagesView from '../products/ProductPackagesView'

export default {
    name: 'SupplierImportedProductPackages',
    mixins: [ SupplierImportedProductMixin ],
    components: { ProductPackagesView },
    data () {
        return {
            packageConfiguration: null,
            loading: false,
            type: null,
            confirmationDialog: {
                isOpen: false,
            },
        }
    },
    watch: {
        supplierProductImportedDetails () {
            this.storeInitialData()
        },
    },
    computed: {
        productConfiguration () {
            return {
                packageConfiguration: this.supplierProductImportedDetails.packageConfiguration || [],
                attributeConfiguration: {
                    selectedAttributes: this.supplierProductImportedDetails.attributeConfiguration,
                },
                productConfigurationName: this.supplierProductImportedDetails.productName,
            }
        },
        payload () {
            if (this.type === 'auto') {
                return {
                    packageConfiguration: {
                        autoGenerated: true,
                    },
                }
            }

            return {
                packageConfiguration: this.packageConfiguration,
            }
        },
        isAutoGenerating () {
            return this.supplierProductImportedDetails && this.supplierProductImportedDetails.packageConfiguration && !Array.isArray(this.supplierProductImportedDetails.packageConfiguration)
        },
    },
    methods: {
        setConfiguration ($event) {
            this.packageConfiguration = $event
        },
        checkAndSave () {
            if (this.supplierProductImportedDetails && this.supplierProductImportedDetails.packageGenerationRecommended === true) {
                this.confirmationDialog.isOpen = true
                return
            }

            this.save()
        },
        save (options = {}) {
            this.confirmationDialog.isOpen = false

            const onSuccess = () => {
                this.skipCheckForChanges = true
                this.$emit('pushRoute', { name: 'SupplierImportedProductCutoff' })
            }
            if (options.regenerate) {
                this.$emit('save', {
                    packageConfiguration: {
                        autoGenerated: true,
                    },
                    onSuccess,
                })
            } else {
                this.$emit('save', { ...this.payload, onSuccess })
            }
        },
        checkForChanges () {
            if (!this.skipCheckForChanges && JSON.stringify(this.initialValues.packageConfiguration) !== JSON.stringify(this.packageConfiguration)) {
                return true
            }
            return false
        },
        storeInitialData () {
            if (!this.supplierProductImportedDetails || !this.supplierProductImportedDetails.packageConfiguration) {
                return
            }

            this.initialValues.packageConfiguration = JSON.parse(JSON.stringify(this.supplierProductImportedDetails.packageConfiguration || []))
            if (this.initialValues.packageConfiguration.length) {
                this.type = 'manual'
            }
        },
    },
    created () {
        this.storeInitialData()
    },
}
</script>
